@import '../scss/settings.scss';

.contentPanel {
    box-shadow: $box-shadow;
    margin-bottom: 35px;
    padding: 0 20px 10px 20px;
    overflow: scroll;
}

.contentPanel_header {
    display: none;
}

.contentPanel_label {
    margin-right: 5px;
}

.contentPanel_warn {
    color: $color-error;
    font-weight: bold;
}


@media only screen and (max-width: $media-xsmall) {
    .contentPanel {
        box-shadow: none;
        padding: 0;
    }

    .contentPanel_header {
        display: block;
        position: relative;
        padding: 5px;
        font-size: $font-size-medium;
        text-align: right;
        text-transform: uppercase;
        color: $color-dark;
        background-color: $color-border;
    }
}
