@import '../scss/settings.scss';

.menu_background {
    z-index: 200;
    position: initial;
    display: none;
}

.menu_group {
    border-bottom: 1px solid $color-light;
}

.menu_group:last-child {
    border-bottom: 0;
}

.menu_subMenu {
    max-height: 0;
    transition: max-height 0.8s;
}

.menu_subMenu a {
    transform: translateX(-80px);
    transition: transform 0.33s;
}

.menu_subMenuSelected {
    max-height: 1000px;
}

.menu_subMenuSelected a {
    transform: translateX(0px);
}

button.menu_item, a.menu_item, a.menu_item:visited {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 0;
    color: inherit;

    svg {
        fill: $color-icon-inactive;
        width: 35px;
        height: 35px;
    }
}

a.menu_itemSelected, a.menu_itemSelected:visited {
    svg {
        fill: $color-icon-active;
    }
}

.menu_title {
    font-size: $font-size-small;
    margin: 5px 0;
    text-transform: uppercase;
}

.menu_itemApply {
    display: flex;
    justify-content: center;

    button.menu_item {
        svg {
            fill: $color-dark;
        }
    }
}

.menu_subMenu a:nth-child(1) {
    transition-delay: 0.1s;
}

.menu_subMenu a:nth-child(2) {
    transition-delay: 0.2s;
}

.menu_subMenu a:nth-child(3) {
    transition-delay: 0.3s;
}

.menu_subMenu a:nth-child(4) {
    transition-delay: 0.4s;
}

.menu_subMenu a:nth-child(5) {
    transition-delay: 0.5s;
}

@media only screen and (max-width: $media-small) and (min-width: $media-xsmall) {
    button.menu_item, a.menu_item, a.menu_item:visited {
        svg {
            width: 25px;
            height: 25px;
        }
    }

    .menu_title {
        display: none;
    }
}

@media only screen and (max-width: $media-xsmall) {
    .menu_subMenu {
        max-height: initial;
    }

    .menu_subMenu a {
        transform: initial;
    }

    .menu_backgroundToggled {
        display: initial;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}