@import '../scss/settings.scss';

.replicaSetsPanel_replicas {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.replicaSetsPanel_label {
    font-size: $font-size-small;
}

.replicaSetsPanel_switch {
    display: flex;
    flex-direction: column;
    align-items: center;
}