
#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
	padding-top: 15px;
	margin-bottom: 25px;
}

#header > .button_headerAction {
	transform: translateY(-80px);
	transition-duration: $animation-length;
	transition-property: transform;
}

#header > .button_headerAction > svg {
	transform: rotate(180deg);
	transition-duration: $animation-length;
	transition-property: transform;
}

#header.header_ready > .button_headerAction {
	transform: translateY(0);
}

#header > .button_headerAction > svg {
	transform: rotate(0);
}

.header_label {
	font-size: $font-size-large;
}

.header_fill {
    flex: 1;
}

input[type="text"].header_filter {
    border: none;
    margin: 0 10px;
	padding: .25rem 0;
    text-align: right;

	transition-duration: $animation-length;
	transition-property: border-color, padding;

	&:hover {
		padding: .25rem;
	}

	&:focus {
		padding: .25rem;
		border-color: $color-light;
	}
}

#header .button_headerAction:nth-child(1) {
    transition-delay: 0.1s;
}

#header .button_headerAction:nth-child(2) {
    transition-delay: 0.2s;
}

#header .button_headerAction:nth-child(3) {
    transition-delay: 0.3s;
}

#header .button_headerAction:nth-child(4) {
    transition-delay: 0.4s;
}

#header .button_headerAction:nth-child(5) {
    transition-delay: 0.5s;
}

#header .button_headerAction:nth-child(6) {
    transition-delay: 0.6s;
}

#header .button_headerAction:nth-child(7) {
    transition-delay: 0.7s;
}

#header .button_headerAction:nth-child(8) {
    transition-delay: 0.8s;
}

#header .button_headerAction:nth-child(9) {
    transition-delay: 0.9s;
}


@media only screen and (max-width: $media-xsmall) {
	.header_label {
		font-size: $font-size-medium;
	}

	#header {
		margin-bottom: 5px;
	}
}