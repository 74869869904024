@import './settings.scss';
@import './normalize.scss';

@import './elements/a.scss';
@import './elements/charts.scss';
@import './elements/contentPanel.scss';
@import './elements/header.scss';
@import './elements/modal.scss';
@import './elements/select.scss';
@import './elements/svg.scss';
@import './elements/table.scss';
@import './elements/textarea.scss';
@import './elements/textbox.scss';

html, body, div, input, select {
	box-sizing: border-box;
}

html, body, #root {
	min-height: 100vh;
	background-color: $color-background;
}

html {
	min-width: 320px;
	font-weight: 300;
	font-family: $font;
	font-size: $font-size;
}

#root {
	display: flex;
	flex-direction: column;
	color: $color-font;
}

#shell {
	display: flex;
	min-height: calc(100vh - 55px);
}

#menu {
    background-color: $color-menu;
    padding: 8px;
}

#content {
	padding: 0 45px;
	flex: 1;
	width: calc(100vw - 72px);
}

#titleBar {
	height: 55px;
	background-color: $color-accent;
	display: flex;
	align-items: center;
	padding: 0 8px;
	font-size: $font-size-large;
    justify-content: space-between;
    z-index: 1;
}

.titleBar_logo {
	fill: #fff;
	width: auto;
	height: 45px;
	margin-top: 4px
}

button.titleBar_hamburger {
	display: none;
}

.titleBar_hamburger > svg {
	fill: #fff;
}

.smallText {
    color: $color-light;
    font-size: $font-size-small;
}

.metadata-type {
	margin-top: 2px;
}

%node-ready-status-shared {
  font-size: $font-size-large;
}

.node-ready-status-True {
  @extend %node-ready-status-shared;
  color: $color-ok;
}

.node-ready-status-False {
  @extend %node-ready-status-shared;
  color: $color-warn;
}

.node-ready-status-Unknown {
  @extend %node-ready-status-shared;
  color: $color-error;
}

.node-master {
	color: $color-icon;
}

.center {
	text-align: center;
}

@media only screen and (max-width: $media-medium) {
	.optional_medium {
		display: none;
	}
}

@media only screen and (max-width: $media-small) {
	.optional_small {
		display: none;
	}

	#menu {
		width: 44px
	}

	#content {
		width: calc(100vw - 44px);
	}
}

@media only screen and (max-width: $media-xsmall) {
	#content {
		padding: 0 15px;
		width: 100vw;
	}

	.optional_xsmall {
		display: none;
	}

	#menu {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 200;
		width: 72px;
		overflow-y: scroll;
		box-shadow: $box-shadow;

		transform: translateX(-80px);
    	transition: transform 0.33s;
	}

    #menu.menu_toggled {
		transform: translateX(0);
    }

	button.titleBar_hamburger {
		display: initial;
	}

	.titleBar_logo {
		height: 35px;
	}
}
