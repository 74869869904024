@import '../scss/settings.scss';

.editorModal {
    display: flex;
    width: 75vw;
    height: 75vh;
}

.editorModal_edit {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.editorModal_input {
    margin: 0px;
    padding: 10px;
    flex: 1;
    font-family: monospace;
}

.editorModal_docs {
    flex: 1;
    overflow: scroll;
}

.editorModal_noDocs {
    text-align: center;
}

.editorModal_spacer {
    flex: 1;
}

@media only screen and (max-width: $media-xsmall) {
    .editorModal {
        width: initial;
        max-width: initial;
        max-height: initial;
        height: calc(100vh - 40px);
    }
}
