
svg {
    fill: $color-icon;
    width: 25px;
    height: 25px;
}

.svg_neutral {
    fill: $color-dark;
}

.svg_warn {
    fill: $color-warn;
}

.svg_error {
    fill: $color-error;
}

.svg_button {
    cursor: pointer;
}