@import '../scss/settings.scss';

.secretValue {
    display: flex;
    align-items: center;
}

.secretValue_text {
    margin-left: 10px;
}

.secretValue_textHidden {
    @extend .secretValue_text;
    color: transparent;
    text-shadow: 0 0 5px $color-font;
}