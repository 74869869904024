
$ct-series-names: (a, b, c);

$ct-series-colors: (
    $color-accent,
    $color-error,
    $color-light,
);

.charts_container {
    margin-top: 5px;
    margin-bottom: 25px;
}

.charts {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.charts_item {
    color: $color-dark;
    box-shadow: $box-shadow;
    background-color: $color-background;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 10px;
}

.charts_item:first-child {
    margin-left: 0;
}

.charts_item:last-child {
    margin-right: 0;
}

.charts_chart {
    flex: 1;
}

.charts_donut {
    display: grid;
    justify-content: center;
    align-items: center;
}

.charts_number {
    font-size: $font-size-xlarge
}

.chart_donutLabel {
    grid-column: 1;
    grid-row: 1;
    font-size: $font-size-large;
}

.charts_itemLabel {
    font-size: $font-size-medium;
    margin-top: 8px;
    text-align: right;
    text-transform: uppercase;
}

.charts_itemSubLabel {
    color: $color-light;
    font-size: $font-size-small;
    text-transform: uppercase;
}

.chart_innerLabel {
    color: $color-light;
    font-size: $font-size-small;
}

.chart_dots {
    display: none;
}

.chart_dotSelected {
    color: $color-light;
}

@media only screen and (max-width: $media-small) {
	.chart_donutLabel {
		font-size: $font-size;
	}

    .charts_itemLabel {
        font-size: $font-size;
    }
}

@media only screen and (max-width: $media-xsmall) {
    .charts {
        flex-wrap: nowrap;
        overflow-x: hidden;
    }

    .chart_1 > .charts_item {
        transform: translateX(calc(-1 * (100vw - 16px)));
    }

    .chart_2 > .charts_item {
        transform: translateX(calc(-2 * (100vw - 16px)));
    }

    .chart_3 > .charts_item {
        transform: translateX(calc(-3 * (100vw - 16px)));
    }

    .charts_item {
        margin: 10px;
        flex: 0 0 calc(100vw - 36px);

        transition-duration: $animation-length;
        transition-property: transform;
    }

    .charts_item:first-child {
        margin-left: 3px;
    }

    .charts_item:last-child {
        margin-right: 3px;
    }

    .chart_dots {
        display: block;
        text-align: center;
    }
}




.donut {
    width: 150px;
    height: 150px;
    grid-column: 1;
    grid-row: 1;
    pointer-events: none;
}

.donut_layer {
    fill: transparent;
    stroke-width: 6;
}

.donut_background {
    @extend .donut_layer;
    stroke: $color-light;
}

.donut_layer1 {
    @extend .donut_layer;
    stroke: $color-accent;
}

.donut_layer2 {
    @extend .donut_layer;
    stroke: $color-error;
}
