@import '../scss/settings.scss';

.field_set {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.field_name {
    font-weight: bold;
    flex: 0 0 120px;
    text-align: right;
}

.field_value {
    margin-left: 20px;
    word-break: break-all;
}


@media only screen and (max-width: $media-small) {
    .field_set {
        flex-direction: column;
        align-items: flex-start;
    }

    .field_name {
        text-align: left;
        flex: 0;
    }

    .field_value {
        margin-left: 0;
    }
}