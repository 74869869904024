@import '../scss/settings.scss';

@mixin disabled-button {
    border: none;
    background: $color-disabled;
    color: $color-font-alt;
    pointer-events: none;
}

.button, .button:visited {
	border: none;
	background: $color-accent;
	color: $color-font-alt;
	cursor: pointer;
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0.5rem;
	margin: 0.5rem 1px;
	min-width: 5rem;
}

.button_negative, .button_negative:visited {
	@extend .button;
	background: $color-error;
	color: $color-font-alt;
}

.button_clear, .button_clear:visited {
	@extend .button;
	background: none;

	padding: 0;
	margin: 0;
	min-width: 3rem;
}

.button:disabled, .button_negative:disabled {
	@include disabled-button;
}

.button_clear:disabled {
	@include disabled-button;
	background: none;
	color: $color-disabled;

	> svg {
		fill: $color-disabled;
	}
}

.button_headerAction {
	@extend .button_clear
}

.button_label {
	color: $color-font;
	font-size: $font-size-small;
	text-transform: uppercase;
	margin-top: 3px;
}