
table {
	width: 100%;
	border-spacing: 3px;
}

thead > tr > th {
	color: $color-light;
	font-weight: normal;
	text-transform: uppercase;
	text-align: left;
	padding: 0.75rem;
	border-bottom: 1px solid $color-border;
}

td {
	padding: 0.75rem;
}

.th_icon {
	width: 20px;
}

.td_icon {
	text-align: center;
}

.td_iconLabel {
    font-size: $font-size-small;
    margin-top: -2px;
    text-transform: uppercase;
}

@media only screen and (max-width: $media-xsmall) {
	thead > tr > th {
		padding: 0.75rem 0.25rem;
	}

	td {
		padding: 0.75rem 0.25rem;
	}

	.wrapped > thead {
		display: none;
	}

	.wrapped > tbody > tr {
		display: flex;
		flex-direction: column;
		margin-bottom: 35px;
	}

	.wrapped > tbody > tr > td {
		padding: 0.25rem;
	}

	.wrapped .wrapped_name {
		font-weight: bold;
	}
}
