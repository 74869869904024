@import '../scss/settings.scss';

.auth {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    background: $color-background;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.auth_input {
    font-size: $font-size-large;
    padding: 0.5rem;
    width: 400px;
    margin: 0 5px;
}

.auth_button {
    border: solid 1px $color-accent;
    font-size: $font-size-large;
}

@media only screen and (max-width: $media-small) {
    .auth {
        flex-direction: column;
    }

    .auth_input {
        width: 300px;
    }
}
