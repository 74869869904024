
textarea {
    white-space: pre;
	color: $color-font;
	background: transparent;
	padding: .25rem 0;
	border: 1px solid transparent;
    resize: none;
    outline: none;
    border-color: $color-border;
	transition-duration: $animation-length;
    transition-property: border-color, padding;

	&:focus {
		outline: none !important;
		border-color: $color-light;
	}
}